import React from "react"


import Layout from '../components/layout'
import { Blog } from '../components/blog'
import { SmallTitlePage } from '../components/common/smallTitlePage'
import Seo from '../components/seo'

const TorunBlog = () => (
  <Layout torun>
    <Seo
      title="Torun Blog"
      description="Blog BodyMed Group to miejsce, w którym dowiesz się więcej o zabiegach i poznasz tajniki pielęgnacji skóry twarzy i ciała w domu. Zapraszamy!"
      url="https://bodymedgroup.pl/torun-blog"
    />
    <SmallTitlePage
      title='Blog' 
    />
    <Blog/>
  </Layout>
)

export default TorunBlog
